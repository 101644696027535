import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'

const IndexPage = () => (
  <EnLayout>
    <SEO title='CONTACT' />
    <Header siteTitle='CONTACT' />

    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6'>
        <h1 className="oro" style={{ "fontWeight": "900" }} >   OR-PRINTER </h1>
          <p>
            <b>Tlf:</b> +34 934 660 303 <br />
            <b>Mov:</b> +34 638 716 267
            <br />
            <b>Contact schedule:</b> 07:00-15:00 Spanish time
            <br />
          </p>
          <p>
            <b>Email:</b> orprinter@orprinter.com
          </p>
          <p><b>Address:</b><br />
            C/ Joan Ràfols, 26
            <br />
            08921
            <br />
            Santa Coloma de Gramenet
            <br />
            Barcelona
            <br />
            España
          </p>
        </div>
        <div className='col-12 col-md-6 '>
          <iframe id='iframemap'
            width='600'
            height='350'
            frameBorder='0'
            scrolling='no'
            marginHeight='0'
            marginWidth='0'
            src='http://maps.google.es/maps?f=q&amp;source=s_q&amp;hl=es&amp;geocode=&amp;q=joan+rafols+26,+santa+coloma&amp;aq=&amp;sll=40.396764,-3.713379&amp;sspn=12.291229,28.54248&amp;vpsrc=6&amp;ie=UTF8&amp;hq=&amp;hnear=Carrer+Joan+R%C3%A0fols,+26,+08921+Santa+Coloma,+Barcelona,+Catalu%C3%B1a&amp;ll=41.452126,2.212916&amp;spn=0.002955,0.006968&amp;z=14&amp;output=embed'
          ></iframe>
        </div>
      </div>
    </Container>
  </EnLayout>
)

export default IndexPage
